import React from 'react';
import { withRouter } from 'react-router-dom';
import { List } from 'antd';

import { Helmet } from 'react-helmet';
import rnt2 from '../img/rnt2.png';
import rnt5 from '../img/rnt5.png';


function Services() {

    const data = [
        {
            title: 'Deshed treatments'
        }, {
            title: 'Skunk treatments'
        }, {
            title: 'Teeth brushing'
        }, {
            title: 'Nail Buffing'
        }, {
            title: 'Anal gland expression'
        }, {
            title: 'Flea & tick baths'
        }, {
            title: 'Mud bath treatment (Helps to detoxify and calm itchy problem skin)'
        }
    ];

    return (
        <div className="container p-5">
            <h2 className="bb">Our Services</h2>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="text-center d-flex h-100 justify-content-space-around flex-direction-column">
                        <img src={rnt5} width="500" className="img-fluid align-self-center mb-4" loading="lazy" />
                        <img src={rnt2} width="500" className="img-fluid align-self-center mb-4" loading="lazy" />
                        
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Our Services</title>
                        <meta name="description" content="Dog Salon Services" />
                    </Helmet>

                    <p className="indent">
                        Our number one priority is the health and safety of your pet. 
                        We ensure that your pets time with us is as enjoyable as possible. 
                        We feel that energy is so important to a dogs psyche especially when they are away from their loved ones and
                        we want all pets to feel comfortable with us. We take pride in being able to work with any pet from young puppies
                        that are just getting used to the grooming process to older senior pets ,and pets with special needs that may be fearful, nervous or even a little feisty.
                        We want both you and your dog to have an exceptional experience, that’s why we will always keep you informed of any changes in your dogs health or 
                        behavior while in our care and take the time to provide our clients with the tools and knowledge necessary to help keep your dog clean, healthy, and well groomed!
                    </p>
                    <hr />
                    <p><b>Cleansing bath, brush, and hand blow dry (where the magic starts!)</b></p>

                    <div>
                        <p className="indent">We use all natural luxury pet products using the best product that best suits your pets skin and coat needs. All products are pH balanced for animals using soothing ingredients to help keep your dogs skin and coat beautiful, healthy and protected. Each bath includes:</p>
                            <ul>
                            <li>
                                Two cleansing hand washes to ensure optimal cleaning
                                </li>
                            <li>
                                Moisturizing blueberry facial (tearless face wash that cleans deodorizing and moisturizes your pets face) while helping reduce any tear staining.
                                </li>
                            <li>
                                Towel dry
                                </li>
                            <li>
                                Full blow out of your pets coat, leaving it soft and voluminous while removing dead undercoat.
                                </li>
                            <li>
                                Nail trimming
                                </li>
                            <li>
                                Ear cleaning with deodorizing non-alcohol ear cleaner.
                                </li>
                            <li>
                                Brush and comb out
                                </li>
                        </ul>
                    </div>

                    <hr />

                    <p><b>The Groom! (The finishing Touch!)</b></p>
                    <p className="indent">
                        Our groomers are well-versed in all breed cuts from specific cuts tailored to each breed such as the Bichon, Poodle and Schnauzer.
                        Our stylists are also well-versed on the popular “puppy cut “and teddy bear trim which is a hand scissored haircut that ranges in length to your liking and your dogs lifestyle.
                        We have the experience and knowledge to make each pet look fabulous while bringing out their best characteristics and features - this is our passion!
                    </p>

                    <hr />

                    <p><b>** No shaving of your dogs coat will ever be done without your permission, as the dogs owner. **</b></p>

                    <hr />

                    <p><b>We also offer:</b></p>

                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<header>{item.title}</header>}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default withRouter(Services);