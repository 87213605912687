import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import './NavMenu.css';
import logo from '../img/logo.png';

import { FaInstagram, FaFacebook } from 'react-icons/fa';

import { Menu } from 'antd';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            selectedKeys: []
        };
    }

    handleClick = (e, key) => {
        this.setState({
            selectedKeys: [e.key]
        });
        switch (e.key) {
            case 'homeBtn':
                this.props.history.push({
                    pathname: '/'
                });
                break;
            case 'aboutBtn':
                this.props.history.push({
                    pathname: '/about'
                });
                break;
            case 'servicesBtn':
                this.props.history.push({
                    pathname: '/our-services'
                });
                break;
            case 'faqBtn':
                this.props.history.push({
                    pathname: '/faq'
                });
                break;
            case 'reviewsBtn':
                this.props.history.push({
                    pathname: '/reviews'
                });
                break;
            case 'contactBtn':
                this.props.history.push({
                    pathname: '/contact'
                });
                break;
            case 'ourTeamBtn':
                this.props.history.push({
                    pathname: '/our-team'
                });
                break;
            case 'photosBtn':
                this.props.history.push({
                    pathname: '/photos-and-videos'
                });
                break;
            default:
                break;
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    directHome = () => {
        this.setState({
            selectedKeys: []
        });

        this.props.history.push({
            pathname: '/'
        });
    }

    render() {
        return (
            <Fragment>
                <div className="custom-mini-menu">
                    <a href="https://goo.gl/maps/CXpsyJhvSNDWjLW96" target="_blank" rel="noopener noreferrer">251 Sullivan Avenue, South Windsor, CT 06074</a>
                    &nbsp; | &nbsp;
                    <a href="tel:8607858640">(860) 785-8640</a>
                    &nbsp; | &nbsp;
                    <a href="https://www.instagram.com/ruffandtumblect/" target="_blank" rel="noopener noreferrer"><FaInstagram className="fs-15rem instagram-icon mr-3" /></a>
                    <a href="https://www.facebook.com/Ruff-and-Tumble-dog-salon-113230163409278" target="_blank" rel="noopener noreferrer"><FaFacebook className="fs-15rem facebook-icon mr-3" /></a>
                </div>
                <div className="custom-menu">
                    <img
                        src={logo}
                        height="85"
                        className="d-inline-block align-top hover pl-3 pb-1 nav-logo"
                        alt="Ruff And Tumble"
                        onClick={this.directHome}
                    />
                    <Menu onClick={this.handleClick} mode="horizontal" className="justify-content-center" selectedKeys={this.state.selectedKeys}>

                        <Menu.Item key="homeBtn">
                            Home
                        </Menu.Item>
                        <Menu.Item key="servicesBtn" className="">
                            Our Services
                        </Menu.Item>
                        <Menu.Item key="reviewsBtn" className="">
                            Reviews
                        </Menu.Item>
                        <Menu.Item key="faqBtn" className="">
                            FAQ's
                        </Menu.Item>
                        <Menu.Item key="ourTeamBtn" className="">
                            Our Team
                        </Menu.Item>
                        <Menu.Item key="photosBtn" className="">
                            Photos and Videos
                        </Menu.Item>
                        <Menu.Item key="contactBtn" className="">
                            Contact
                        </Menu.Item>
                    </Menu>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(NavMenu);