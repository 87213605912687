import React from 'react';
import { withRouter } from 'react-router-dom';
import rnt2 from '../img/rnt2.png';

import { Helmet } from 'react-helmet';

function About() {
    return (
        <div className="container p-5">
            <h2 className="bb">About Ruff & Tumble</h2>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="text-center d-flex h-100 justify-content-space-around flex-direction-column">
                        <img src={rnt2} width="500" className="img-fluid align-self-center" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>About Ruff & Tumble</title>
                        <meta name="description" content="About Ruff And Tumble" />
                    </Helmet>


                    <div>
                <p>
                            Ruff & Tumble is a reimagined dog salon for pets that are truly a part of the family.
                </p>
                <p>
                            After spending 18 years in the grooming industry (and winning many awards along the way) Stephanie decided to open her very own dog salon to offer an exceptional experience to clients.
                </p>
                <p>
                            Ruff & Tumble specializes in hand-scissoring and dogs with behavioral issues or ones who may be fearful of grooming. It is a relaxed, quiet, and stress-free environment.
                </p>

                <p>
                    <b>
                                We require all dogs to be up-to-date with their vaccines. We require verification through a rabies certificate license or a rabies tag.
                    </b>
                </p>

                <p>
                            We are located at 251 Sullivan Avenue in South Windsor, Connecticut. Book now at <a href="tel:8607858640">(860) 785-8640</a>
                </p>

            </div>
                </div>
            </div>
        </div>

       
    );
}

export default withRouter(About);