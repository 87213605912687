import React from 'react';
import { withRouter } from 'react-router-dom';

import commercial from '../img/commercial.mp4';

import bath from '../img/bath.jpg';
import blep from '../img/blep.jpg';
import floof from '../img/floof.jpg';
import crate from '../img/crate.jpg';
import group from '../img/group.jpg';
import pose from '../img/pose.jpg';
import tired from '../img/tired.jpg';
import tongue from '../img/tongue.jpg';

import { Helmet } from 'react-helmet';

function PhotosAndVideo() {
    return (
        <div>
            <div className="container pt-4 text-center">
                <h2 className="mb-0">Small Business Spotlight</h2>
                <h6 className="mb-5">Check us out below!</h6>
            </div>

            <div className="row m-5 pad-items">
                <div className="col col-lg-3 col-12">
                    <img src={pose} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={blep} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={tongue} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={tired} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={group} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={bath} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={crate} className="img-fluid" loading="lazy" />
                </div>
                <div className="col col-lg-3 col-12">
                    <img src={floof} className="img-fluid" loading="lazy" />
                </div>
            </div>
            <hr />
            <div className="row m-5">
                <div className="col col-lg-4 col-12">


                    <video controls src={commercial} type="video/mp4" className="img-fluid" />
                </div>

                <div className="col col-lg-4 col-12">
                    <iframe width="640" height="360" src="https://www.youtube.com/embed/WoNAxaVw3nA" className="mw-100" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="col col-lg-4 col-12">
                    <iframe width="640" height="360" src="https://www.youtube.com/embed/UKnilfpzoNY" className="mw-100" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div className="col col-lg-4 col-12">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F113230163409278%2Fvideos%2F1025546647951753%2F&show_text=false&width=560&t=0" width="640" height="360" className="mw-100" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </div>

                <div className="col col-lg-4 col-12">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F113230163409278%2Fvideos%2F1477473742452279%2F&show_text=false&width=560&t=0" width="640" height="360" className="mw-100" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>

    );
}

export default withRouter(PhotosAndVideo);