import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import NavMenu from './NavMenu';
import logo from '../img/logo.jpg';

import { FaInstagram, FaFacebook } from 'react-icons/fa';

class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        console.log(props);
    }

    handleClick = () => {
        this.props.history.push({
            pathname: '/new-blog-post'
        });
    }

    render() {
        return (
            <div className="wrapper">
                <NavMenu />
                <div className="body">
                    {this.props.children}
                </div>
                <footer>
                    <div className="row">
                        <div className="col col-lg-6 col-12 text-center my-auto">
                            <p>251 Sullivan Avenue</p>
                            <p>South Windsor, CT 06074</p>
                            
                            <a href="https://www.instagram.com/ruffandtumblect/" target="_blank" rel="noopener noreferrer"><FaInstagram className="fs-2rem instagram-icon mr-3" /></a>
                            <a href="https://www.facebook.com/Ruff-and-Tumble-dog-salon-113230163409278" target="_blank" rel="noopener noreferrer"><FaFacebook className="fs-2rem facebook-icon mr-3" /></a>
                        </div>
                        <div className="col col-lg-6 col-12 text-center">
                            <img src={logo} height={200} alt="Ruff and Tumble" className="img-fluid" />

                        </div>
                        <div className="col col-12">
                            <div>Ruff & Tumble &#169; {new Date().getFullYear()}. All rights reserved.</div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default withRouter(Layout);