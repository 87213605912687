import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { List, Avatar, Space } from 'antd';
import { MessageOutlined, StarFilled, StarOutlined } from '@ant-design/icons';

import Axios from '../config/axios';

function Reviews() {

    const [reviews, setReviews] = useState([]);


    useEffect(() => {
        Axios.get(`/api/GetYelpReviews`
        ).then(response => {
            setReviews(response.data.reviews);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    return (
        <div className="container p-5">
            <h2 className="bb">Reviews</h2>
            <List
                itemLayout="horizontal"
                dataSource={reviews}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={item.user?.image_url} />}
                            title={<a href="https://ant.design">{item.user?.name}</a>}
                            description={
                                <>
                                <p className="overflow-y-scroll">
                                        {item.text} {<a href={item.url} target="_blank" rel="noopener noreferrer">Read more on Yelp</a>}
                                    </p>
                                    {[...Array(item.rating)].map((x, i) =>
                                        <StarFilled style={{ color: "rgb(244, 13, 21)" }} />
                                    )}
                                    
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
            <a href="https://www.yelp.com/biz/ruff-and-tumble-dog-salon-south-windsor" target="_blank" rel="noopener noreferrer">Read all reviews on Yelp</a>
        </div>


    );
}

export default withRouter(Reviews);