import React, { Component } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import 'antd/dist/antd.css';
import './custom.css'
import About from './components/About';
import FAQ from './components/FAQ';
import Services from './components/Services';
import Contact from './components/Contact';
import ScrollToTop from './helpers/ScrollToTop';
import OurTeam from './components/OurTeam';
import Reviews from './components/Reviews';
import PhotosAndVideo from './components/PhotosAndVideo';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
        <ScrollToTop>
          <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/about' component={About} />
                <Route path='/faq' component={FAQ} />
                <Route path='/our-services' component={Services} />
                  <Route path='/contact' component={Contact} />
                  <Route path='/our-team' component={OurTeam} />
                  <Route path='/reviews' component={Reviews} />
                  <Route path='/photos-and-videos' component={PhotosAndVideo} />
             </Layout>
          </ScrollToTop>
    );
  }
}
