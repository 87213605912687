import React from 'react';
import { withRouter } from 'react-router-dom';
import rnt1 from '../img/rnt1.png'
import logo from '../img/circle_logo.png'

function Home(props) {

    function directToContact() {
        let path = '/contact';
        props.history.replace(path);
    }

    return (
        <div>
            <div className="hc-1 pb-5 justify-content-center">
                <div className="col text-center">
                    <img src={logo} className="img-fluid" style={{ width: '400px' }} />
                </div>
            </div>
            <div className="text-center hc-1 pb-5">
                <img src={rnt1} className="img-fluid" />
            </div>
            <div className="container mx-auto p-5">
                <p>We focus on providing upscale experiences for pets that are a part of your family.
                We have a completely renovated environment and offer stress-free grooming with luxury pet products.
                With over 18 years of experience, Ruff & Tumble strives to meet breed standards regarding haircuts.
                    Learn more below and get in touch to <span className="a hover" onClick={directToContact}>book an appointment</span>.</p>

                <hr />

                {/*<div className="text-center">*/}
                {/*    <h2 className="mb-0">Small Business Spotlight</h2>*/}
                {/*    <h6 className="mb-5">Check us out below!</h6>*/}
                {/*    <iframe width="347" height="195" src="https://www.youtube.com/embed/WoNAxaVw3nA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                {/*</div>*/}

                <hr />

                <div className="row">
                    <div className="contact row text-center container mx-auto pt-5 pb-5">

                        <div className="col col-md-8 col-12 margin5-0">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.7616714758947!2d-72.5956138838531!3d41.854956374839944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e6f8bfbc458865%3A0xc4c01334cce21d22!2s251%20Sullivan%20Ave%2C%20South%20Windsor%2C%20CT%2006074!5e0!3m2!1sen!2sus!4v1632056062028!5m2!1sen!2sus"
                                width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" className="mw-100"></iframe>
                        </div>
                        <div className="col col-md-4 col-12 text-center margin5-0">
                            

                            <div className="row mt-5">
                                <div className="col-12">
                                    <span className="fa fa-calendar"></span>
                                    <p>Monday-Saturday: 8am-5pm<br /> Sunday: 9am-5pm<br /></p>
                                </div>

                                <div className="col-12 mt-5">
                                    <span className="fa fa-envelope"></span>
                                    <a href="mailto:ruffandtumblect@gmail.com">

                                        <p>ruffandtumblect@gmail.com</p>
                                    </a>
                                </div>

                                <div className="col-12 mt-5">
                                    <span className="fa fa-phone"></span>
                                    <p>
                                        <a href="tel:8607858640">(860) 785-8640</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
  
}

export default withRouter(Home);