import React from 'react';
import { withRouter } from 'react-router-dom';

import { List } from 'antd';
import { Container } from 'reactstrap';

import { Helmet } from 'react-helmet';
import rnt6 from '../img/rnt6.jpg';

function FAQ(){

        const data = [
            {
                title: 'Do you have age limit for pets coming into salon?',
                content: <div className="row">
                    <div className="col col-sm-3 col-12"><img src={rnt6} width="250" className="img-fluid align-self-center" loading="lazy" /></div>
                    <div className="col" style={{ flexDirection: 'column-reverse', flexGrow: 4}}><p>We do have an age limit of 14 on small dogs (anything 14 or older), 12 for big dogs (anything 12 years or older), and 11 years for extra large breeds (anything 11 years or older) because we do not have a vet on staff. The animals safety and well-being is our number one priority.
                    We wouldn’t want anything to happen healthwise due to age without having a vet on staff. Our employees are not trained to perform medical practices or procedures, nor do we suggest having your senior pet groomed in a salon that does not have medical staff present.
                        </p></div>
                    </div>
            },
            {
                title: 'How much will my dogs grooming cost?',
                content: <p>
                    We Believe that every dog has unique needs and requirements to achieve a quality service. That’s why our pricing is specific to each dog on an individual and personal level. 
                    Breed, coat type, coat condition, and style of haircut are all key points in pricing our clients correctly. 
                    Consultations are always free, and even though we will try to pinpoint our best with an over the phone estimate, please know prices are subject to change but always to be communicated with the owner prior to providing service.
                    Calling and speaking to one of our team members over the phone is the best way to obtain pricing and service questions. (Please allow 24 hours to return messages)
                    </p>
            },
            {
                title: 'How long will my pets grooming take?',
                content: <p>
                    Dogs that require haircuts are with us 3 to 4 hours, and dogs that are only our cleanse and blow dry service are with us for 2 to 3 hours. 
                    We like to take our time with each dog to ensure that their visit at the salon is as stress-free and enjoyable as possible. 
                    Expedited services can be accommodated at an additional fee, and need to be scheduled accordingly prior to appointment. 
                </p>
            },
            {
                title: 'Should I shave my dog to help shedding?',
                content: <p>
                    No!! Dogs that have double coats such as golden retrievers, Siberian huskies, and labradors need their extra coat for insulation. 
                    Not only from the cold but from the heat. 
                    If shaved, the coat will most likely not grow back the same and will cause your dog discomfort with anything other than mild weather.
                    We highly recommend not shaving unless your dog has health issues, is a senior, can’t handle brushing or if your vet recommends it.
                </p>
            },
            {
                title: 'How often should I groom my dog?',
                content: <p>
                    We usually say it’s great to have your dog groomed every 6 to 8 weeks. 
                    Not only for your pets health and hygiene, but you keep them on a good schedule and routine as pets adapt very well to structure and routine.
                    Doodle breeds should typically be groomed every 4 to 6 weeks depending on the length of coat. 
                    Regular grooming along with at home maintenance like brushing and combing regularly, play an important role in making sure your doodle doesn’t have to be shaved due to matting. 
                </p>
            },
            {
                title: 'When should I bring my new puppy in?',
                content: <p>We recommend bringing your puppy in right after his/her last set of puppy shots. 
                Usually around 16 weeks of age. We offer a "Banana Pup" package at a discounted rate for puppies five months and under to encourage an early foundation of grooming and handling. 
                    During the "Banana Pup" package we introduced them to the bath, brushing, combing nail trimming and blow dryer. 
                    Puppies that have long hair will also be introduced to light trimming around their face, paws, and sanitary areas. Since they will be groomed for life we like to make it a happy and positive experience!!

                </p>
            },
            {
                title: 'What shots do you require?',
                content: <p>
                    We require that your pet is up to date on his/her rabies shot unless otherwise written by your vet. We also do recommend whenever your pet is around other pets having his Parvo/distemper shots as well as kennel cough vaccination. 
                </p>
            },
            {
                title: 'Can I stay with my dog while he/she is groomed?',
                content: <p>
                    Unfortunately due to insurance reasons we cannot have you in the grooming area.
                    We also have found that the dog becomes anxious when their owner is around as they are then ready to leave and over excited.
                    Much like kids in pre-school, once you are gone they settle in and get comfortable.
                    We always allow the dog to sniff around and smell the salon a bit to familiarize themselves with their environment. 
                    Dogs are very intuitive and will pick up on any anxiety that you may be feeling so it is important that you be calm and reassuring. 
                </p>
            },
            {
                title: 'Can I bring my own shampoo?',
                content: <p>
                    Absolutely, although our top rated I-Groom product line is designed to promote a healthy coat and skin.
                    We do offer fragrance free and hypoallergenic shampoo if necessary. All of our luxury shampoos are included at no additional charge or upgrade.
                    If your veterinarian has prescribed or suggested an alternative shampoo rather than our product line we will gladly use it. 
                </p>
            }, 
            {
                title: 'Will my dog be in a kennel?',
                content: <p>
                    Ruff & Tumble is not a kennel free facility. During your dogs stay with us they will likely have the opportunity to roam the salon but, may periodically be placed in a kennel for a short time for their safety
                    or to keep all dogs on a kennel/free roam rotation, so everyone has the opportunity to be social. Because not all dogs are social with others, if you would prefer your dog to be kept separate,
                    please let our team members know and we will gladly put them in their own space with water until you pick up. If you prefer your dog not to be kenneled at all please verbalize this at drop off and accommodations can be made.
                </p>
            },
            {
                title: 'Do you use hot kennel dryers?',
                content: <p>
                    No! At Ruff & Tumble we don’t kennel dry any dogs at all. Drying is all done by hand. In the event your dog does not respond well to the hand dryer, it may require us to work with him/her for extra time.
                </p>
            }
        ];


        return (
            <Container className="p-5">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Frequently Asked Questions</title>
                    <meta name="description" content="Physical Therapy Frequently Asked Questions" />
                </Helmet>

                <h2 className="bb">Frequently Asked Questions</h2>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<header><b>{item.title}</b></header>}
                                description={item.content}
                            />
                        </List.Item>
                    )}
                />
            </Container>
        );
    
}

export default withRouter(FAQ);