import React from 'react';
import { withRouter } from 'react-router-dom';
import rnt2 from '../img/rnt2.png';

import amanda from '../img/amanda.jpg';
import lindsey from '../img/lindsey.jpg';
import erica from '../img/erica.jpg';
import stephanie from '../img/stephanie.jpg';
import natalia from '../img/natalia.jpg';
import ryann from '../img/ryann.jpg';
import karen from '../img/karen.jpg';
import marissa from '../img/marissa.jpg';

import { Helmet } from 'react-helmet';

function OurTeam() {
    return (
        <div className="container p-5">
            <h2 className="bb">Our Team</h2>
            <div className="row">
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Stephanie (Owner)</b></h3>
                    <img className="br-50 img-fluid" src={stephanie} />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Amanda</b></h3>
                    <img className="br-50 img-fluid" src={amanda} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Lindsey</b></h3>
                    <img className="br-50 img-fluid" src={lindsey} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Erica</b></h3>
                    <img className="br-50 img-fluid" src={erica} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Natalia</b></h3>
                    <img className="br-50 img-fluid" src={natalia} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Ryann</b></h3>
                    <img className="br-50 img-fluid" src={ryann} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Karen</b></h3>
                    <img className="br-50 img-fluid" src={karen} loading="lazy" />
                </div>
                <div className="col col-lg-6 col-12 text-center">
                    <h3 className="mt-5 fs-2rem"><b>Marissa</b></h3>
                    <img className="br-50 img-fluid" src={marissa} loading="lazy" />
                </div>
            </div>
        </div>


    );
}

export default withRouter(OurTeam);