import rnt2 from '../img/building.jpg';

import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import { Form, Input, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Axios from '../config/axios';

import { Helmet } from 'react-helmet';

import { toast } from '@rickylandino/react-messages';

function Contact() {
    const { Option } = Select;


    const [sending, setSending] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const layout = {
        labelCol: {
            span: 24, md: { offset: 4 }
        },
        wrapperCol: {
            span: 16, sm: 24, md: { span: 16, offset: 4 }
        },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: 'Invalid email address',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const [form] = Form.useForm();

    const handleSubmit = (postdata) => {
        setSending(true);

        Axios.post(`/api/GetInTouch`, postdata
        ).then(response => {
            if (response.data) {
                setSending(false);
                form.resetFields();
                toast.success('Thank you for reaching out. I will be in touch as soon as possible!');

            } else {
                console.log(response.data);
                toast.error('Something went wrong. Please reach out to me directly at genevieve@email.com', {duration: 0});
            }
        }).catch(error => {
            console.log(error);
            toast.error('Something went wrong. Please reach out to me directly at genevieve@email.com');
        });

    };

    return (
        <div className="container p-5">
            <h2 className="bb">Contact Us</h2>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="text-center d-flex h-100 justify-content-space-around flex-direction-column">
                        <img src={rnt2} width="800" className="img-fluid align-self-center" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Contact Us</title>
                        <meta name="description" content="Contact Ruff And Tumble" />
                    </Helmet>

                        <Form form={form} {...layout} name="nest-messages" onFinish={handleSubmit} validateMessages={validateMessages}>
                            <Form.Item name='name' label="Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name='email' label="Email" rules={[{ type: 'email' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name='description' label="Message">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                                {sending && <Spin indicator={antIcon} />}
                                <button className="btn btn-primary float-left">
                                    Submit
        </button>
                            </Form.Item>
                        </Form>
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="contact row text-center container mx-auto pt-5 pb-5">

                    <div className="col col-md-8 col-12 margin5-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.7616714758947!2d-72.5956138838531!3d41.854956374839944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e6f8bfbc458865%3A0xc4c01334cce21d22!2s251%20Sullivan%20Ave%2C%20South%20Windsor%2C%20CT%2006074!5e0!3m2!1sen!2sus!4v1632056062028!5m2!1sen!2sus"
                            width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" className="mw-100"></iframe>
                    </div>
                    <div className="col col-md-4 col-12 text-center margin5-0">


                        <div className="row mt-5">
                            <div className="col-12">
                                <span className="fa fa-calendar"></span>
                                <p>Monday-Saturday: 8am-5pm<br /> Sunday: 9am-5pm<br /></p>
                            </div>

                            <div className="col-12 mt-5">
                                <span className="fa fa-envelope"></span>
                                <a href="mailto:ruffandtumblect@gmail.com">

                                    <p>ruffandtumblect@gmail.com</p>
                                </a>
                            </div>

                            <div className="col-12 mt-5">
                                <span className="fa fa-phone"></span>
                                <p>
                                    <a href="tel:8607858640">(860) 785-8640</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default withRouter(Contact);